.seq-tempo {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .range {
        transform: none !important;
    }
    .slider-container {
        width: 100%;
        height: 19px;
    }
}
.sequencer {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    max-width: 500px;

    .randomize {
        border-bottom: 1px solid #444;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .line {
            background-color: #444;
            width: 1px;
            height: 20px;
        }
    }

    .track {
        display: flex;
        flex-direction: row;
        margin-bottom: 8px;
        width: 100%;
        max-width: 500px;
        justify-content: center;
        .step {
            width: 6%;
            background-color: transparent;
            border: 1px solid #252525;
            opacity: .8;
            margin-right: 2px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            &.track-random {
                margin-right: 0px;
                font-weight: 600;
                color: #666;
                border: 0px solid;
                cursor: pointer;
            }
            &.active {
                background-color: transparent;
                border: 1px solid #444;
            }
            .active {
                background-color: #ddd;
                width: 40%;
                height: 40%;
                border-radius: 50%;
            }
        }
        .step:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }
}